const distributionDomain = process.env.REACT_APP_AWS_DISTRIBUTION_DOMAIN || '';

const wrongDomain = process.env.REACT_APP_WRONG_AWS_DOMAIN || '';

export const getFileUrl = (
  location: string
): { update: boolean; correctDomain: string } => {
  const findWrongDomain = location.includes(wrongDomain);

  const correctDomain = findWrongDomain
    ? location.replace(wrongDomain, distributionDomain)
    : location;

  return {
    update: findWrongDomain,
    correctDomain,
  };
};
