import { Box, TextField, MenuItem, Typography } from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import { useFormikContext } from "formik";

import { CurrencyType, FormValues } from "../../types/payment";
import getAmountRequired from "../../utils/currencyAmountRequired";

type SenderInfoProps = {
  currencies: CurrencyType[];
  method: string;
  type: string | undefined;
  currency: string;
};

const SenderInfo = ({
  currencies,
  method,
  type,
  currency,
}: SenderInfoProps) => {
  const {
    setFieldTouched,
    setFieldValue,
    handleChange,
    values,
    errors,
    touched,
  } = useFormikContext<FormValues>();

  return (
    <>
      <Box sx={{ display: { sm: "flex" } }}>
        <Box mb={1} width="100%" sx={{ marginRight: { sm: 1 } }}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            name="firstName"
            value={values.firstName}
            sx={{ background: "#fff" }}
            onBlur={() => setFieldTouched("firstName")}
            onChange={handleChange("firstName")}
          />

          {errors.firstName && touched.firstName && (
            <Box color="#d32f2f" mt="4px">
              <>{errors.firstName}</>
            </Box>
          )}
        </Box>
        <Box mb={1} width="100%">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            name="lastName"
            value={values.lastName}
            sx={{ background: "#fff" }}
            onBlur={() => setFieldTouched("lastName")}
            onChange={handleChange("lastName")}
          />
          {errors.lastName && touched.lastName && (
            <Box color="#d32f2f" mt="4px">
              <>{errors.lastName}</>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: { sm: "flex" } }}>
        <Box
          mb={1}
          width="100%"
          sx={{ marginRight: { sm: values.isCard === "card" ? 1 : "" } }}
        >
          <TextField
            fullWidth
            id="outlined-basic"
            label="Email"
            type="email"
            variant="outlined"
            name="email"
            value={values.email}
            sx={{ background: "#fff" }}
            onBlur={() => setFieldTouched("email")}
            onChange={handleChange("email")}
          />
          {errors.email && touched.email && (
            <Box color="#d32f2f" mt="4px">
              <>{errors.email}</>
            </Box>
          )}
        </Box>

        {(values.isCard === "card" || method === "card") && (
          <Box mb={1} width="100%">
            <TextField
              select
              fullWidth
              label="Select card currency"
              variant="outlined"
              sx={{ background: "#fff" }}
              name="cardCurrency"
              value={values.cardCurrency}
              onBlur={() => setFieldTouched("cardCurrency")}
              onChange={handleChange("cardCurrency")}
            >
              {currencies.map((currency) => (
                <MenuItem key={currency._id} value={currency.currencyCode}>
                  {currency.country}
                </MenuItem>
              ))}
            </TextField>
            {errors.cardCurrency && touched.cardCurrency && (
              <Box color="#d32f2f" mt="4px">
                <>{errors.cardCurrency}</>
              </Box>
            )}
          </Box>
        )}
      </Box>

      {type === "currency" && (
        <Box sx={{ display: { sm: "flex" } }}>
          <Box
            mb={1}
            width="100%"
            sx={{ marginRight: { sm: values.isCard === "card" ? 1 : "" } }}
          >
            <TextField
              fullWidth
              id="outlined-basic"
              label="Amount"
              type="amount"
              variant="outlined"
              name="amount"
              value={values.amount}
              sx={{ background: "#fff" }}
              onBlur={() => setFieldTouched("amount")}
              onChange={(e) => {
                const value = e.target.value;
                let numericValue = value.replace(/\D/g, "");
                if (numericValue === "") numericValue = "0";

                const formattedValue = parseInt(
                  numericValue,
                  10
                )?.toLocaleString();
                setFieldValue("amount", formattedValue);
              }}
            />

            {errors.amount && touched.amount ? (
              <Box color="#d32f2f" mt="4px">
                <>{errors.amount}</>
              </Box>
            ) : (
              <Typography mt="4px" fontSize="16px">
                Minimum amount is {getSymbolFromCurrency(currency)}
                {getAmountRequired(currency)}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default SenderInfo;
