import React from "react";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";

import { Bank } from "../../types/payment";

import logo from "../../assets/images/logo.png";

type ReceiptProps = {
  bank: Bank | undefined;
  transaction: any;
};

const Receipt = React.forwardRef((props: ReceiptProps, ref) => {
  const { transaction, bank } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      ref={ref}
      justifyContent="center"
      alignItems="center"
      py={2}
      px={2}
      //   Hide content from viewport
      sx={{
        position: "absolute",
        left: "-9999px",
        top: "-9999px",
      }}
    >
      <Box>
        <Box component="img" src={logo} />
        <Typography mb="28px" fontSize="24px" fontWeight="700">
          Transaction Receipt
        </Typography>
      </Box>

      <Box display="flex" flexWrap="wrap" width="520px">
        <Box width="50%" py={1}>
          <Typography color="#61697d">Reference</Typography>
          <Typography maxWidth="50px" color="#0D1028" fontWeight="600">
            {transaction?._id}
          </Typography>
        </Box>
        <Box width="50%" py={1}>
          <Typography color="#61697d">Receiver's Wallet ID</Typography>
          <Typography color="#0D1028" fontWeight="600">
            {transaction?.to_wallet}
          </Typography>
        </Box>

        <Box width="50%" py={1}>
          <Typography color="#61697d">Sender's Name</Typography>
          <Typography color="#0D1028" fontWeight="600">
            {transaction?.payment_link_sender_first_name}{" "}
            {transaction?.payment_link_sender_last_name}
          </Typography>
        </Box>
        <Box width="50%" py={1}>
          <Typography color="#61697d">Payment Method</Typography>
          <Typography color="#0D1028" fontWeight="600">
            {transaction?.payment_method === "manual_banking"
              ? "Bank Transfer"
              : "Card"}
          </Typography>
        </Box>
        <Box width="50%" py={1}>
          <Typography color="#61697d">Receiver's Name</Typography>
          <Typography
            color="#0D1028"
            fontWeight="600"
            textTransform="capitalize"
          >
            {transaction?.receiver?.is_b2b_user
              ? transaction?.receiver?.business_name
              : `${transaction?.receiver?.first_name} ${transaction?.receiver?.last_name}`}
          </Typography>
        </Box>

        <Box width="50%" py={1}>
          <Typography color="#61697d">Amount</Typography>
          <Typography color="#0D1028" fontWeight="600">
            {parseInt(transaction?.amount.toString(), 10)?.toLocaleString()}
            {transaction?.debit_currency}
          </Typography>
        </Box>

        <Box width="50%" py={1}>
          <Typography color="#61697d">Status</Typography>
          <Typography color="#0D1028" fontWeight="600">
            {transaction?.status}
          </Typography>
        </Box>

        <Box width="50%" py={1}>
          <Typography color="#61697d">Transaction Date</Typography>
          <Typography color="#0D1028" fontWeight="600">
            {format(parseInt(transaction?.updatedAt), "do MMMM yyyy, h:mma")}
          </Typography>
        </Box>

        {transaction?.payment_method === "manual_banking" && bank && (
          <>
            <Box width="50%" py={1}>
              <Typography color="#61697d">Payment Country</Typography>
              <Typography color="#0D1028" fontWeight="600">
                {bank.country}
              </Typography>
            </Box>

            <Box width="50%" py={1}>
              <Typography color="#61697d">Payment Bank</Typography>
              <Typography color="#0D1028" fontWeight="600">
                {bank.bank_name}
              </Typography>
            </Box>
            <Box width="50%" py={1}>
              <Typography color="#61697d">Account Name</Typography>
              <Typography color="#0D1028" fontWeight="600">
                {bank.account_name}
              </Typography>
            </Box>
            <Box width="50%" py={1}>
              <Typography color="#61697d">Account Number</Typography>
              <Typography color="#0D1028" fontWeight="600">
                {bank.bank_acc_number}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
});

export default Receipt;
