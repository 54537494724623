import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}`,
  credentials: "include", // Include cookies in the request
  headers: {},
});

console.log(httpLink);
console.log("httpLink.....");

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      isweb: true,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
