import { gql } from "@apollo/client";

export const GET_PAYMENT_LINK_INFO = gql`
  query getPaymentLinkInfo($payment_string: String!) {
    getPaymentLinkInfo(payment_string: $payment_string) {
      wallet_id
      amount
      type
      currency
      paymentInfo {
        payment_code
        currency
        amount
      }
      user {
        _id
        first_name
        last_name
        email
        profile_picture
        payment_code
        business_name
        is_b2b_user
      }
      bank {
        _id
        account_name
        bank_name
        country
        currency
        status
        sort_code
        bank_acc_number
        currency_reference {
          _id
          country
          currencyCode
          currencyName
          status
          flag
          currencySymbol
          countryShortCode
        }
      }
    }
  }
`;

export const PAYMENT_LINK_MANUAL_BANK_TO_WALLET = gql`
  mutation paymentLinkManualBankToWalletTransfer(
    $tx_ref: String!
    $amount: String!
    $total_amount_debited: String!
    $total_amount_credited: String!
    $to_wallet: Int!
    $receiver_id: String!
    $sender_first_name: String
    $sender_last_name: String
    $sender_email: String
    $debit_currency: String!
    $credit_currency: String!
    $manual_bank_id: String!
    $receipt_url: String
    $exchange_rate: String!
    $fee: String!
    $charges: String!
    $purpose: String!
  ) {
    paymentLinkManualBankToWalletTransfer(
      tx_ref: $tx_ref
      amount: $amount
      total_amount_debited: $total_amount_debited
      total_amount_credited: $total_amount_credited
      to_wallet: $to_wallet
      receiver_id: $receiver_id
      sender_first_name: $sender_first_name
      sender_last_name: $sender_last_name
      sender_email: $sender_email
      debit_currency: $debit_currency
      credit_currency: $credit_currency
      manual_bank_id: $manual_bank_id
      receipt_url: $receipt_url
      exchange_rate: $exchange_rate
      fee: $fee
      charges: $charges
      purpose: $purpose
    ) {
      _id
      tx_ref
      tx_count
      manual_banking_tx_ref
      bank_ref
      receipt_url
      type
      payment_method
      payout_method
      collection_partner
      payout_partner
      debit_currency
      credit_currency
      amount
      total_amount
      total_amount_debited
      total_amount_credited
      receiver {
        _id
        first_name
        last_name
        email
        is_b2b_user
        business_name
      }
      payment_link_sender_first_name
      payment_link_sender_last_name
      payment_link_sender_email
      to_wallet
      to_wallet_creditted_amount
      from_wallet_debitted_amount
      fee
      charges
      exchange_rate
      collection_status
      payout_status
      status
      createdAt
      updatedAt
    }
  }
`;

export const PAYMENT_LINK_CARD_TO_WALLET = gql`
  mutation paymentLinkCardToWalletPayment(
    $amount: String!
    $total_amount_debited: String!
    $total_amount_credited: String!
    $debit_currency: String!
    $credit_currency: String!
    $to_wallet: Int!
    $receiver_id: String!
    $exchange_rate: String!
    $fee: String!
    $charges: String!
    $purpose: String!
    $sender_first_name: String!
    $sender_last_name: String!
    $sender_email: String!
  ) {
    paymentLinkCardToWalletPayment(
      amount: $amount
      total_amount_debited: $total_amount_debited
      total_amount_credited: $total_amount_credited
      debit_currency: $debit_currency
      credit_currency: $credit_currency
      to_wallet: $to_wallet
      receiver_id: $receiver_id
      exchange_rate: $exchange_rate
      fee: $fee
      charges: $charges
      purpose: $purpose
      sender_first_name: $sender_first_name
      sender_last_name: $sender_last_name
      sender_email: $sender_email
    ) {
      status
      message
      mode
      redirect_url
      fields
      tx_id
    }
  }
`;

export const PAYMENT_LINK_MOMO_TO_WALLET = gql`
  mutation paymentLinkMomoToWallet(
    $amount: String!
    $total_amount_debited: String!
    $total_amount_credited: String!
    $debit_currency: String!
    $credit_currency: String!
    $to_wallet: Int!
    $receiver_id: String!
    $exchange_rate: String!
    $fee: String!
    $charges: String!
    $purpose: String!
    $sender_first_name: String!
    $sender_last_name: String!
    $sender_email: String!
    $momo_number: String!
    $momo_country: String!
    $momo_operator: String!
  ) {
    paymentLinkMomoToWallet(
      amount: $amount
      total_amount_debited: $total_amount_debited
      total_amount_credited: $total_amount_credited
      debit_currency: $debit_currency
      credit_currency: $credit_currency
      to_wallet: $to_wallet
      receiver_id: $receiver_id
      exchange_rate: $exchange_rate
      fee: $fee
      charges: $charges
      purpose: $purpose
      sender_first_name: $sender_first_name
      sender_last_name: $sender_last_name
      sender_email: $sender_email
      momo_number: $momo_number
      momo_country: $momo_country
      momo_operator: $momo_operator
    ) {
      _id
      tx_ref
      tx_count
      manual_banking_tx_ref
      bank_ref
      receipt_url
      type
      payment_method
      payout_method
      collection_partner
      payout_partner
      debit_currency
      credit_currency
      amount
      total_amount
      total_amount_debited
      total_amount_credited
      receiver {
        _id
        first_name
        last_name
        email
        is_b2b_user
        business_name
      }
      payment_link_sender_first_name
      payment_link_sender_last_name
      payment_link_sender_email
      to_wallet
      to_wallet_creditted_amount
      from_wallet_debitted_amount
      fee
      charges
      exchange_rate
      collection_status
      payout_status
      status
      createdAt
      updatedAt
    }
  }
`;

export const VALIDATE_PIN = gql`
  mutation validatePin($pin: String!, $tx_ref: String!) {
    validatePin(pin: $pin, tx_ref: $tx_ref) {
      mode
      redirect_url
      tx_ref
      flw_ref
    }
  }
`;

export const VALIDATE_OTP = gql`
  mutation validateOTP($otp: String!, $tx_ref: String!, $flw_ref: String!) {
    validateOTP(otp: $otp, flw_ref: $flw_ref, tx_ref: $tx_ref) {
      _id
      tx_ref
      tx_count
      manual_banking_tx_ref
      bank_ref
      receipt_url
      type
      payment_method
      payout_method
      collection_partner
      payout_partner
      debit_currency
      credit_currency
      amount
      total_amount
      total_amount_debited
      total_amount_credited
      receiver {
        _id
        first_name
        last_name
        email
      }
      payment_link_sender_first_name
      payment_link_sender_last_name
      payment_link_sender_email
      to_wallet
      to_wallet_creditted_amount
      from_wallet_debitted_amount
      fee
      charges
      exchange_rate
      collection_status
      payout_status
      status
      createdAt
      updatedAt
    }
  }
`;

export const VALIDATE_AVS = gql`
  mutation validateAVS(
    $city: String
    $address: String
    $state: String
    $country: String
    $zipcode: String
    $tx_ref: String!
  ) {
    validateAVS(
      city: $city
      address: $address
      state: $state
      country: $country
      zipcode: $zipcode
      tx_ref: $tx_ref
    ) {
      mode
      redirect_url
      tx_ref
      flw_ref
    }
  }
`;

export const GET_SUPPORTED_CURRENCIES = gql`
  query {
    getCurrencies {
      sourceCurrencies {
        _id
        country
        currencyCode
        currencyName
        flag
        currencySymbol
        status
        countryShortCode
        excluded
      }
    }
  }
`;

export const GET_MOBILE_MONEY_COUNTRIES = gql`
  query getMobileMoneyCountries($payout_partner: String) {
    getMobileMoneyCountries(payout_partner: $payout_partner) {
      _id
      name
      currency
      countryShortCode
    }
  }
`;

export const GET_PAYOUT_PARTNER = gql`
  query getPayoutPartner($flow: String!, $method: String!, $currency: String!) {
    getPayoutPartner(flow: $flow, method: $method, currency: $currency) {
      partner
    }
  }
`;

export const GET_MOBILE_MONEY_OPERATORS = gql`
  query getMobileMoneyOperators($partner: String, $currency: String) {
    getMobileMoneyOperators(partner: $partner, currency: $currency)
  }
`;
