import { Box, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";
import getSymbolFromCurrency from "currency-symbol-map";

import { playStoreUrl, appStoreUrl } from "../../utils/constant";
import playStore from "../../assets/images/play-store.png";
import appStore from "../../assets/images/app-store.png";
import marker from "../../assets/images/success-marker.png";

type CompletedProps = {
  onDownload: () => void;
  downloading: boolean;
  transaction: any;
};

const Completed = (props: CompletedProps) => {
  const { onDownload, downloading, transaction } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      flexBasis="50%"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          mb={1}
          width="124px"
          height="124px"
          borderRadius="70px"
          component="img"
          src={marker}
          sx={{
            marginTop: { xs: "35px", lg: "0px" },
          }}
        />
      </Box>

      <Typography
        mt="17px"
        mb="29px"
        maxWidth="246px"
        component="h1"
        color="#29304D"
        fontWeight="700"
        lineHeight="normal"
        fontSize="20px"
        textAlign="center"
      >
        You sent {getSymbolFromCurrency(transaction?.credit_currency)}
        {parseInt(
          transaction?.total_amount_credited as string,
          10
        )?.toLocaleString()}{" "}
        to{" "}
        <Box component="span" textTransform="capitalize">
          {transaction?.receiver?.is_b2b_user
            ? transaction?.receiver?.business_name
            : transaction?.receiver?.first_name}{" "}
        </Box>
        wallet
      </Typography>
      <Typography
        mb="17px"
        maxWidth="343px"
        textAlign="center"
        color="#000"
        fontWeight="400"
        lineHeight="normal"
        fontSize="16px"
        letterSpacing="-0.1px"
      >
        You can download straitPay to start spending and investing in Africa
      </Typography>

      <Box>
        <Box
          display="flex"
          sx={{
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              padding: 0,
              width: "50%",
              minWidth: "230px",
              paddingRight: { sm: "8px" },
              marginBottom: { xs: "12px", sm: "0" },
            }}
          >
            <Button
              target="_blank"
              href={playStoreUrl}
              sx={{ padding: 0, width: "100%" }}
            >
              <Box width="100%" component="img" src={playStore} />
            </Button>
          </Box>

          <Box
            sx={{
              padding: 0,
              width: "50%",
              minWidth: "230px",
              paddingLeft: { sm: "8px" },
            }}
          >
            <Button
              target="_blank"
              href={appStoreUrl}
              sx={{ padding: 0, width: "100%" }}
            >
              <Box width="100%" component="img" src={appStore} />
            </Button>
          </Box>
        </Box>

        <Box
          p="12px"
          mt="26px"
          mb="16px"
          display="flex"
          flexWrap="wrap"
          borderRadius="24px"
          sx={{ background: "#fff" }}
        >
          <Box p={1} sx={{ flexBasis: { xs: "100%", sm: "50%" } }}>
            <Box
              color="#61697D"
              fontSize="16px"
              lineHeight="normal"
              fontWeight="400"
            >
              Recipient Name
            </Box>
            <Box
              color="#0D1028"
              fontSize="18px"
              fontWeight="600"
              lineHeight="normal"
              letterSpacing="-0.2px"
              textTransform="capitalize"
            >
              {transaction?.receiver?.is_b2b_user
                ? transaction?.receiver?.business_name
                : `${transaction?.receiver?.first_name} ${transaction?.receiver?.last_name}`}
            </Box>
          </Box>

          <Box p={1} sx={{ flexBasis: { xs: "100%", sm: "50%" } }}>
            <Box
              color="#61697D"
              fontSize="16px"
              lineHeight="normal"
              fontWeight="400"
            >
              Payment Method
            </Box>
            <Box
              color="#0D1028"
              fontSize="18px"
              fontWeight="600"
              letterSpacing="-0.2px"
              lineHeight="normal"
            >
              {transaction?.payment_method === "manual_banking"
                ? "Bank Transfer"
                : transaction?.payment_method === "mobile"
                ? "Momo"
                : "Card"}
            </Box>
          </Box>

          <Box p={1} sx={{ flexBasis: { xs: "100%", sm: "50%" } }}>
            <Box
              color="#61697D"
              fontSize="16px"
              lineHeight="normal"
              fontWeight="400"
            >
              Amount
            </Box>
            <Box
              color="#0D1028"
              fontSize="18px"
              fontWeight="600"
              lineHeight="normal"
              letterSpacing="-0.2px"
            >
              {getSymbolFromCurrency(transaction?.credit_currency)}
              {parseInt(
                transaction?.total_amount_credited as string,
                10
              )?.toLocaleString()}
            </Box>
          </Box>

          <Box p={1} sx={{ flexBasis: { xs: "100%", sm: "50%" } }}>
            <Box
              color="#61697D"
              fontSize="16px"
              lineHeight="normal"
              fontWeight="400"
            >
              Transaction Date
            </Box>
            <Box
              color="#0D1028"
              fontSize="18px"
              fontWeight="600"
              lineHeight="normal"
              letterSpacing="-0.2px"
            >
              {format(parseInt(transaction?.updatedAt), "do MMMM yyyy, h:mma")}
            </Box>
          </Box>
        </Box>

        <Button
          sx={{
            height: "60px",
            color: "#fff",
            width: "100%",
            fontSize: "17px",
            fontWeight: "700",
            borderRadius: "15px",
            textTransform: "none",
            background: "#BA3A90",
            "&:hover": { background: "#BA3A90", color: "#fff" },
          }}
          onClick={onDownload}
          loading={downloading}
          component={LoadingButton}
        >
          Download Receipt
        </Button>
      </Box>
    </Box>
  );
};

export default Completed;
