const CurrencyPayoutLimits = {
  NGN: {
    lowerLimit: 1000,
    upperLimit: 1000000,
  },
  GHS: {
    lowerLimit: 30,
    upperLimit: 10000,
  },
  RWF: {
    lowerLimit: 300,
    upperLimit: 1000000,
  },
  CAD: {
    lowerLimit: 3,
    upperLimit: 1000,
  },
  KES: {
    lowerLimit: 100,
    upperLimit: 90000,
  },
  XOF: {
    lowerLimit: 100,
    upperLimit: 400000,
  },
  default: {
    lowerLimit: 0,
    upperLimit: 1000000,
  },
};

export const getCurrencyAmountRequired = (currency: string) => {
  const limits =
    CurrencyPayoutLimits[currency as keyof typeof CurrencyPayoutLimits] ||
    CurrencyPayoutLimits.default;
  return limits.lowerLimit;
};

export default getCurrencyAmountRequired;
